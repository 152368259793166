import React, { useMemo } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Card } from '@fairwindsops/ui-components';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import LineChart from '~reactComponents/charts/LineChart/LineChart.react';

import { strings } from '~utils/strings';
import { ActionItemReportLineChartData } from '~utils/global.types.react';

import './ActionItemsReportsLineChart.react.scss';

type ActionItemsReportsLineChartProps = {
  chartTitle?: string;
  data: ActionItemReportLineChartData[];
  isLoading?: boolean;
  chartColors?: string[];
  tooltip?: string;
};

const ActionItemsReportsLineChart = ({
  chartColors,
  chartTitle,
  data,
  isLoading,
  tooltip,
}: ActionItemsReportsLineChartProps) => {
  const lineChartProps = useMemo(
    () => ({
      data,
      margin: { top: 5, right: 50, bottom: 55, left: 60 },
      axisBottom: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      },
      axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      },
      colors: chartColors,
      tooltip: (data: any) => (
        <div className="action-items-reports-line-chart__tooltip">
          <strong>{`${chartTitle}${strings.punctuation.colon}`}</strong>
          <span>{data?.data?.y}</span>
        </div>
      ),
      yScale: {
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      },
    }),
    [data, chartColors],
  );

  return (
    <div>
      <Card className="action-items-reports-line-chart">
        <Card.Title className="action-items-reports-line-chart__title">
          {chartTitle ? (
            <div>
              {strings.actionItemsReports.cumulative} {chartTitle}{' '}
              {strings.actionItemsReports.itemsOverTime}
            </div>
          ) : (
            `${strings.actionItemsReports.cumulative} ${strings.actionItemsReports.itemsOverTime}`
          )}
          {chartTitle ? (
            <div>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id={`tooltip-action-items-reports-line-chart`}>
                    <Popover.Content>{tooltip}</Popover.Content>
                  </Popover>
                }
              >
                <InfoIcon />
              </OverlayTrigger>
            </div>
          ) : null}
        </Card.Title>
        <Card.Body>
          {isLoading && (
            <LoadingSpinner containerClassNames="action-items-reports-line-chart__spinner" />
          )}
          {!isLoading && data?.length > 0 ? (
            <div className="action-items-reports-line-chart__chart">
              <LineChart {...lineChartProps} />
            </div>
          ) : null}
          {!isLoading && !data?.length ? (
            <div className="action-items-reports-line-chart__no-aggregator">
              {strings.general.noDataToDisplay}
            </div>
          ) : null}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ActionItemsReportsLineChart;
