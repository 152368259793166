import { ActionItemReportData, ActionItemReportDataObject } from '~utils/global.types.react';

import { convertJsonToCsv, downloadCsv } from '~utils/helpers';

type CreateCSVFileParams = {
  data: ActionItemReportData[];
  org: string;
  reportType: string;
};

const formatActionItemReportData = (data: ActionItemReportData[], reportType: string) => {
  if (!data?.length || !reportType) {
    return [];
  }

  const formattedActionItemReportData = [];

  for (const reportData of data) {
    if (!reportData) {
      continue;
    }

    formattedActionItemReportData.push({
      dates: reportData.time,
      events: reportData.period[reportType as keyof ActionItemReportDataObject],
      cumulative: reportData.cumulative[reportType as keyof ActionItemReportDataObject],
    });
  }

  return formattedActionItemReportData;
};

const getCSVFilename = (orgName: string): string => `action_items_reports_${orgName}`;

export const createCSVFile = async ({ data, org, reportType }: CreateCSVFileParams) => {
  downloadCsv(
    await convertJsonToCsv(formatActionItemReportData(data, reportType), [
      'dates',
      'events',
      'cumulative',
    ]),
    getCSVFilename(org),
  );
};
