import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useContextMenu } from 'react-contexify';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Select, { ActionMeta, SingleValue, StylesConfig } from 'react-select';
import timezone from 'dayjs/plugin/timezone';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';

import {
  ACTION_ITEMS,
  ACTION_ITEMS_REPORTS_PRINT_PREVIEW,
  ORGS,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import AdvancedFilters from '~reactComponents/AdvancedFilters/AdvancedFilters.react';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import Tag from '~reactComponents/Tags/Tag.react';

import ActionItemsReportsBarChart from './components/ActionItemsReportsBarChart/ActionItemsReportsBarChart.react';
import ActionItemsReportsLineChart from './components/ActionItemsReportsLineChart/ActionItemsReportsLineChart.react';

import { formatDatetime, getCurrentTimezone, handlePageChange } from '~reactHelpers';
import {
  IStore,
  IRouter,
  IRoute,
  DateType,
  OptionType,
  SelectedFilters,
  AdvancedFilterOption,
  ActionItemReportData,
  ActionItemReportChartData,
  ActionItemReportDataObject,
  ActionItemReportLineChartData,
} from '~utils/global.types.react';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import { COLORS } from '~utils/styling';

import logger from '~logger';

import { Team } from '../ActionItems.types.react';
import {
  ACTION_ITEMS_REPORTS_CONTEXT_MENU_ID,
  ADVANCED_FILTERS_MAPPER,
  CHART_COLOR_MAPPER,
  CUMULATIVE_TOOLTIP_CONTENT_MAPPER,
  DEFAULT_FILTERS,
  REVERSED_SEARCH_PARAMS_MAPPER,
  ReportStatusOptionType,
  SEARCH_PARAMS_MAPPER,
  SELECTED_REPORT_STATUS,
  TEAM,
  TEAMS,
  TOOLTIP_CONTENT_MAPPER,
} from './ActionItemsReports.config.react';

import { createCSVFile } from './ActionItemsReports.helpers.react';

import ContextMenu from './components/ContextMenu/ContextMenu.react';

import './ActionItemsReports.react.scss';
import { DropdownIndicator } from '~reactComponents/DropdownIndicator/DropdownIndicator.react';

dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

export type ActionItemsReportsProps = {
  route: IRoute;
  router: () => IRouter;
  selectedCluster?: string;
  store: () => IStore;
};

const ActionItemsReports = ({ route, router, selectedCluster, store }: ActionItemsReportsProps): JSX.Element => {
  const organization = store().getters.organization;
  const baseURL = `/v0/organizations/${organization.Name}`;
  const timeZone = getCurrentTimezone();
  const urlParams = useRef<URLSearchParams>(new URLSearchParams(window.location.search));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTimeRange, setSelectedTimeRange] = useState<DateType>({
    start:
      route?.query?.startTime ||
      formatDatetime(timeZone ? dayjs().tz(timeZone).subtract(1, 'year') : dayjs().subtract(1, 'year')),
    end: route?.query?.endTime || formatDatetime(timeZone ? dayjs().tz(timeZone) : dayjs()),
  });
  const [reportStatuses, setReportStatuses] = useState<ReportStatusOptionType[]>([
    { value: 'open', label: strings.actionItemsReports.open },
    { value: 'introduced', label: strings.actionItemsReports.introduced },
    { value: 'resolved', label: strings.actionItemsReports.manuallyResolved },
    { value: 'fixed', label: strings.actionItemsReports.fixed },
    { value: 'deleted', label: strings.actionItemsReports.deleted },
  ]);
  const [reportStatus, setReportStatus] = useState<ReportStatusOptionType>({
    value: 'fixed',
    label: strings.actionItemsReports.fixed,
  });
  const [advancedFilters, setAdvancedFilters] = useState<Record<string, string[]>>(DEFAULT_FILTERS);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});
  const [reportsData, setReportsData] = useState<ActionItemReportData[]>([]);
  const [reportsBarChartData, setReportsBarChartData] = useState<ActionItemReportChartData[]>([]);
  const [reportsLineChartData, setReportsLineChartData] = useState<ActionItemReportLineChartData[]>([]);
  const [isPrintPreview, setIsPrintPreview] = useState<boolean>(false);
  const [teams, setTeams] = useState<Team[]>([]);
  const [timePeriod, setTimePeriod] = useState<string>(strings.actionItemsReports.month);

  useEffect(() => {
    setIsPrintPreview(route.name === ACTION_ITEMS_REPORTS_PRINT_PREVIEW);
  }, [route.name]);

  useEffect(() => {
    if (route.query?.reportStatus && reportStatuses?.length) {
      const defaultReportStatus = reportStatuses.find((status) => status.value === route.query?.reportStatus);
      setReportStatus(
        defaultReportStatus || {
          value: 'fixed',
          label: strings.actionItemsReports.fixed,
        },
      );
    }
  }, [route.query, reportStatuses]);

  const transformActionItemFilters = (actionItemFilters: Record<string, string[]>, teams: Team[]) => {
    if (!actionItemFilters) {
      return;
    }

    const actionItemFilterKeys = Object.keys(actionItemFilters);
    const transformedActionItemFilters = {} as Record<string, string[]>;

    for (const key of actionItemFilterKeys) {
      if (ADVANCED_FILTERS_MAPPER[key as keyof typeof ADVANCED_FILTERS_MAPPER]) {
        transformedActionItemFilters[ADVANCED_FILTERS_MAPPER[key]] = actionItemFilters[key];
      }
    }

    if (teams?.length) {
      transformedActionItemFilters[TEAM] = teams.map((team) => team.Name);
    }

    return transformedActionItemFilters;
  };

  const initSelectedFilters = () => {
    const selectedFilters = {} as SelectedFilters;

    const paramEntries = Array.from(urlParams.current);

    for (const [key, value] of paramEntries) {
      const section = REVERSED_SEARCH_PARAMS_MAPPER[key];

      if (!section) {
        continue;
      }

      const option = {
        label: value,
        value,
        section,
        isDisabled: !!(section === 'Cluster' && selectedCluster),
      };

      selectedFilters[section] = selectedFilters[section] ? [...selectedFilters[section], option] : [option];
    }

    setSelectedFilters({ ...selectedFilters });
  };

  const getTeams = async () => {
    try {
      setTeams((await sendRequest('GET', `${baseURL}/teams`, {}, null)) || []);
    } catch (e: any) {
      logger.logError('error_retrieving_teams_action_item_filters_reports', e);
    }
  };

  const getActionItemFilters = async () => {
    try {
      const actionItemFilters = await sendRequest(
        'GET',
        selectedCluster
          ? `${baseURL}/clusters/${selectedCluster}/action-item-filters?${urlParams.current.toString()}`
          : `${baseURL}/action-item-filters?${urlParams.current.toString()}`,
        {},
        null,
      );

      setAdvancedFilters({
        ...DEFAULT_FILTERS,
        ...transformActionItemFilters(actionItemFilters, teams),
      });
      initSelectedFilters();
    } catch (e: any) {
      logger.logError('error_retrieving_action_item_filters_reports', e);
    }
  };

  const getTeamByName = (name: string) => (name && teams?.length ? teams.find((team) => team.Name === name) : null);

  const getTeamIdsByUrlParams = (urlParams: URLSearchParams) => {
    const paramEntries = Array.from(urlParams);

    const teamIds = [];

    for (const [key, value] of paramEntries) {
      if (key === TEAMS) {
        const selectedTeam = getTeamByName(value);

        if (selectedTeam) {
          teamIds.push(selectedTeam.ID);
        }
      }
    }

    return teamIds;
  };

  const filterTeamsParams = (urlParams: URLSearchParams) => {
    const teamIds = getTeamIdsByUrlParams(urlParams);

    if (!teamIds?.length) {
      return;
    }

    urlParams.delete(TEAMS);

    for (const id of teamIds) {
      urlParams.append(TEAMS, String(id));
    }
  };

  const filterStartDateAndEndDateParams = (urlParams: URLSearchParams) => {
    urlParams.delete('startTime');
    urlParams.delete('endTime');
  };

  const filterReportStatus = (urlParams: URLSearchParams) => {
    urlParams.delete('reportStatus');
  };

  const filterURLParams = () => {
    const filteredURLParams = new URLSearchParams(window.location.search);

    filterStartDateAndEndDateParams(filteredURLParams);
    filterReportStatus(filteredURLParams);
    filterTeamsParams(filteredURLParams);

    return filteredURLParams;
  };

  const getReportsData = async () => {
    if (!selectedTimeRange?.start || !selectedTimeRange?.end) {
      return;
    }

    setIsLoading(true);

    const url = `${baseURL}/action-items/statistics?startTime=${selectedTimeRange.start}&endTime=${selectedTimeRange.end}`;

    try {
      const response = await sendRequest(
        'GET',
        urlParams.current ? `${url}&${filterURLParams().toString()}` : url,
        {},
        null,
      );

      setReportsData(response || []);

      setIsLoading(false);
    } catch (e: any) {
      logger.logError('error_retrieving_action_item_reports_data', e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    getActionItemFilters();
  }, [teams]);

  useEffect(() => {
    getActionItemFilters();
    getReportsData();
  }, [selectedCluster, window.location.search, selectedTimeRange]);

  useEffect(() => {
    if (selectedCluster) {
      urlParams.current.delete('cluster');
      urlParams.current.append('cluster', selectedCluster);
    }
  }, [selectedCluster]);

  const filterBarChartData = () => {
    if (!reportStatus || !reportsData?.length) {
      setReportsBarChartData([]);
      return;
    }

    let barChartData = [] as { label: string; value: number }[];
    let isExisting = false;

    for (const data of reportsData) {
      if (!data) {
        continue;
      }

      const label = `${dayjs(data.time).format(
        timePeriod === strings.actionItemsReports.day ? 'YYYY-MM-DD' : 'MMM YYYY',
      )}`;

      const value = data.period[reportStatus.value as keyof ActionItemReportDataObject];

      barChartData = barChartData.map((data) => {
        if (data.label === label) {
          data.value = data.value + value;
          isExisting = true;
        }

        return data;
      });

      if (!isExisting) {
        barChartData.push({
          label,
          value,
        });
      }

      isExisting = false;
    }

    setReportsBarChartData(barChartData);
  };

  const filterLineChartData = () => {
    if (!reportStatus || !reportsData?.length) {
      setReportsLineChartData([]);
      return;
    }

    const lineChartData = { id: 1, data: [] } as ActionItemReportLineChartData;
    let isExisting = false;

    for (const data of reportsData) {
      if (!data) {
        continue;
      }

      const x = `${dayjs(data.time).format(timePeriod === strings.actionItemsReports.day ? 'YYYY-MM-DD' : 'MMM YYYY')}`;

      const y = data.cumulative[reportStatus.value as keyof ActionItemReportDataObject];

      lineChartData.data = lineChartData.data.map((data) => {
        if (data.x === x) {
          data.y = data.y + y;
          isExisting = true;
        }

        return data;
      });

      if (!isExisting) {
        lineChartData.data.push({
          x,
          y,
        });
      }

      isExisting = false;
    }

    setReportsLineChartData([lineChartData]);
  };

  const filterReportsData = () => {
    filterBarChartData();
    filterLineChartData();
  };

  useEffect(() => {
    filterReportsData();
  }, [reportStatus, reportsData, timePeriod]);

  const findTimePeriod = () =>
    selectedTimeRange?.start &&
    selectedTimeRange?.end &&
    dayjs(selectedTimeRange.end).diff(dayjs(selectedTimeRange.start), 'month') <= 0
      ? strings.actionItemsReports.day
      : strings.actionItemsReports.month;

  useEffect(() => {
    urlParams.current.delete('startTime');
    urlParams.current.delete('endTime');

    appendURLParam('StartTime', selectedTimeRange.start);
    appendURLParam('EndTime', selectedTimeRange.end);

    setTimePeriod(findTimePeriod());

    router()
      .replace({ path: `${route.path}?${urlParams.current.toString()}` })
      .catch(() => {});
  }, [selectedTimeRange]);

  useEffect(() => {
    if (reportStatus) {
      urlParams.current.delete('reportStatus');
      appendURLParam('ReportStatus', String(reportStatus.value));
      router()
        .replace({ path: `${route.path}?${urlParams.current.toString()}` })
        .catch(() => {});
    }
  }, [reportStatus]);

  useEffect(() => {
    if (isPrintPreview) {
      logger.logEvent('view_page:action-items-reports-print-preview');

      const selectedReportStatus = localStorage.getItem(SELECTED_REPORT_STATUS);

      if (selectedReportStatus) {
        setReportStatus(JSON.parse(selectedReportStatus));
      }
    }

    return () => {
      localStorage.removeItem(SELECTED_REPORT_STATUS);
    };
  }, [isPrintPreview]);

  const filterByDate = (start?: Dayjs | Date, end?: Date | Dayjs) => {
    if (!start || !end) {
      return;
    }

    const changedDates = {
      start: formatDatetime(timeZone ? dayjs(start).tz(timeZone) : dayjs(start)),
      end: formatDatetime(timeZone ? dayjs(end).tz(timeZone) : dayjs(end)),
    };
    setSelectedTimeRange(changedDates);
  };

  const breadcrumbsList = [
    {
      id: ORGS,
      label: organization.Name,
      href: `/orgs`,
    },
    {
      id: ACTION_ITEMS,
      label: strings.navigation.ActionItems,
      href: `/orgs/${organization.Name}/automation`,
    },
    {
      id: 'last',
      label: strings.navigation.reports,
      href: `${window.location.pathname}`,
      isActive: true,
    },
  ];

  const customFilterStyles: StylesConfig<OptionType, true> = {
    control: (provided) => ({
      ...provided,
      minHeight: '1.5rem',
      maxHeight: '4.5rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      alignItems: 'flex-start',
      maxHeight: '4.25rem',
      overflow: 'auto',
      padding: '4px 8px 0px 8px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#EBEEF7',
      color: COLORS.CORE.PRIMARY,
      alignContent: 'center',
    }),
  };

  const onOptionChanged = (option: AdvancedFilterOption, action: string) => {
    const { value, section } = option;

    if (selectedFilters[section]) {
      selectedFilters[section] =
        action === 'deselect-option'
          ? selectedFilters[section].filter((filterValue) => filterValue.value !== value)
          : [...selectedFilters[section], option];
    } else {
      selectedFilters[section] = [option];
    }
  };

  const onOptionRemoved = (removedValue: AdvancedFilterOption) => {
    const { value, section } = removedValue;

    if (!selectedFilters[section]) {
      return;
    }

    selectedFilters[section] = selectedFilters[section].filter((filterValue) => filterValue.value !== value);
  };

  const updateSelectedFilters = (option: AdvancedFilterOption, removedValue: AdvancedFilterOption, action: string) => {
    if (option?.value && option?.section) {
      onOptionChanged(option, action);
    } else if (removedValue?.value && removedValue?.section) {
      onOptionRemoved(removedValue);
    }

    setSelectedFilters(selectedFilters);
  };

  const shouldRemoveParam = (action: string, removedValue: AdvancedFilterOption) => {
    return removedValue || action === 'deselect-option';
  };

  const removeURLParam = (params: URLSearchParams, key: string, valueToRemove: string) => {
    const values = params.getAll(key);
    if (values.length) {
      params.delete(key);
      for (const value of values) {
        if (value !== valueToRemove) {
          params.append(key, value);
        }
      }
    }
    return params;
  };

  const appendURLParam = (section: string, value: string) => {
    if (!section || !value) {
      return;
    }

    urlParams.current.append(SEARCH_PARAMS_MAPPER[section], value);
  };

  const updateSearchParams = (option: AdvancedFilterOption, removedValue: AdvancedFilterOption, action: string) => {
    const value = option?.value || removedValue?.value;
    const section = option?.section || removedValue?.section;

    if (!SEARCH_PARAMS_MAPPER[section]) {
      return;
    }

    if (shouldRemoveParam(action, removedValue)) {
      removeURLParam(urlParams.current, SEARCH_PARAMS_MAPPER[section], value);
      getActionItemFilters();
      getReportsData();
    } else {
      appendURLParam(section, value);
    }

    setTimeout(() => {
      router()
        .replace({ path: `${route.path}?${urlParams.current.toString()}` })
        .catch(() => {});
    });
  };

  const selectFilters = (option: AdvancedFilterOption, removedValue: AdvancedFilterOption, action: string) => {
    updateSelectedFilters(option, removedValue, action);
    updateSearchParams(option, removedValue, action);
  };

  const { show: showContextMenu } = useContextMenu({
    id: ACTION_ITEMS_REPORTS_CONTEXT_MENU_ID,
  });

  const onCSVExported = async () => {
    await createCSVFile({
      data: reportsData,
      org: organization.Name,
      reportType: reportStatus.value,
    });
  };

  const onPrintPreviewOpened = () => {
    router().push({
      path: `/orgs/${organization.Name}/action-items/reports-print-preview?${urlParams.current.toString()}`,
    });
  };

  const filterCriterias = useMemo(() => {
    const currentURLSearchParams = new URLSearchParams(window.location.search);

    currentURLSearchParams.delete('startTime');
    currentURLSearchParams.delete('endTime');

    const paramEntries = Array.from(currentURLSearchParams);

    const criterias = {} as Record<string, string[]>;

    for (const [key, value] of paramEntries) {
      const section = REVERSED_SEARCH_PARAMS_MAPPER[key];

      if (!section) {
        continue;
      }

      criterias[section] = criterias[section] ? [...criterias[section], value] : [value];
    }

    return criterias;
  }, [isPrintPreview, window.location.search]);

  const currentFilterCount = useMemo(() => {
    if (!selectedFilters) {
      return 0;
    }

    const filterKeys = Object.keys(selectedFilters);

    let count = 0;

    if (filterKeys) {
      filterKeys.forEach((key) => {
        count += selectedFilters[key].length;
      });
    }

    return count;
  }, [selectedFilters]);

  return (
    <LayoutReact className="action-items-reports">
      {isPrintPreview ? null : (
        <Breadcrumbs
          data={breadcrumbsList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
      )}
      <div className="action-items-reports__header-container">
        <div className="action-items-reports__filter-container">
          <Datepicker
            disabled={isLoading || isPrintPreview}
            isClearable={false}
            onDateSelect={filterByDate}
            startDate={selectedTimeRange.start}
            endDate={selectedTimeRange.end}
            minDate={12}
            className="action-items-reports"
          />
          <Select
            options={reportStatuses}
            aria-label={strings.actionItemsReports.reportStatuses}
            value={reportStatus}
            classNamePrefix="action-items-reports__report-statuses"
            components={{
              // ignore incompatible type
              // eslint-disable-next-line
              // @ts-ignore
              DropdownIndicator,
            }}
            onChange={(
              newValue: SingleValue<ReportStatusOptionType>,
              actionMeta: ActionMeta<ReportStatusOptionType>,
            ) => {
              setReportStatus(newValue as ReportStatusOptionType);
              localStorage.setItem(SELECTED_REPORT_STATUS, JSON.stringify(newValue));
            }}
            isDisabled={isPrintPreview}
          />
          {isPrintPreview ? null : (
            <AdvancedFilters
              customFilterStyles={customFilterStyles}
              customMenuType={{ filters: advancedFilters, selectedFilters, selectFilters }}
              nCurrentFilters={currentFilterCount}
            />
          )}
        </div>
        {isPrintPreview ? null : (
          <div className="action-items-reports__context-menu-container">
            <button onClick={(e) => showContextMenu({ event: e })}>
              <img src={HorizontalEllipsis} alt={strings.general.ellipsisIcon} />
            </button>
          </div>
        )}
      </div>
      {isPrintPreview && Object.keys(filterCriterias)?.length ? (
        <div className="action-items-reports__filter-criterias-container">
          <div className="action-items-reports__filtering-title-container">
            <span className="action-items-reports__filtering-title">
              {strings.general.filtering}
              {strings.punctuation.colon}{' '}
            </span>
            {Object.keys(filterCriterias).map((criteria) => (
              <span className="action-items-reports__filtering-title-tag">
                <Tag hollow={false}>{criteria}</Tag>
              </span>
            ))}
          </div>
          <div className="action-items-reports__filtering-value-container">
            {Object.entries(filterCriterias).map(([key, value]) => (
              <div>
                <span className="action-items-reports__filtering-value-key">
                  {key}
                  {strings.punctuation.colon}
                </span>
                <span> {value.join(', ')}</span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className="action-items-reports__bar-chart-container">
        <ActionItemsReportsBarChart
          chartTitle={reportStatus.label || ''}
          data={reportsBarChartData}
          isLoading={isLoading}
          chartColors={[CHART_COLOR_MAPPER[reportStatus.value]]}
          tooltip={TOOLTIP_CONTENT_MAPPER(timePeriod)[reportStatus.value]}
        />
        <div className="action-items-reports__line-chart-container">
          <ActionItemsReportsLineChart
            chartTitle={reportStatus.label || ''}
            data={reportsLineChartData}
            isLoading={isLoading}
            chartColors={[CHART_COLOR_MAPPER[reportStatus.value]]}
            tooltip={CUMULATIVE_TOOLTIP_CONTENT_MAPPER(timePeriod)[reportStatus.value]}
          />
        </div>
      </div>
      <ContextMenu handleExport={onCSVExported} handlePrint={onPrintPreviewOpened} />
    </LayoutReact>
  );
};

export default ActionItemsReports;
