import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Select, { StylesConfig } from 'react-select';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';
import AdmissionChart from './components/AdmissionChart/AdmissionChart.react';
import SearchFilterBar from '~reactComponents/SearchFilterBar/SearchFilterBar.react';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import DescriptionPanel from './components/DescriptionPanel/DescriptionPanel.react';
import PaginationComponent from '~reactComponents/pagination/pagination.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ExpandIcon from '~reactComponents/Icons/Expand.icon.react';

import {
  AdmissionActionItemType,
  AdmissionRequestType,
  OptionType,
  RequestCountObjectType,
  RequestCountType,
} from './ReactAdmission.types.react';
import { IStore, IRouter, IRoute } from '~globalTypes';

import { DropdownOptions, handlePageChange } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import { PAGE_SIZE_OPTIONS } from '~utils/constants';
import { strings } from '~utils/strings';
import logger from '~logger';
import { getCurrentTimezone, setURL } from '~reactHelpers';
import { COLORS } from '~utils/styling';

import './ReactAdmission.react.scss';
import { Toaster } from 'react-hot-toast';
import { UPDATE_CLUSTER } from '~store/action.types';

dayjs.extend(utc);
dayjs.extend(timezone);

const todayTz = getCurrentTimezone();
const today = todayTz ? dayjs().tz(todayTz) : dayjs();
const dayAgo = today.clone().subtract(1, 'day');
const options: OptionType[] = [
  { value: 'number', label: strings.admissionController.numberChart },
  { value: strings.percentage, label: strings.admissionController.percentChart },
];

const formatDatetime = (m: Dayjs): string => {
  return m.toISOString();
};

type ReportProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const ReactAdmission = ({ route, router, store }: ReportProps): JSX.Element => {
  const org = route?.params?.org;
  const cluster = route?.params?.cluster;
  const baseURL = `/v0/organizations/${org}`;
  const startTime = route?.query?.startTime || formatDatetime(dayAgo);
  const endTime = route?.query?.endTime || formatDatetime(today);
  const searchQuery = route?.query?.search || '';
  const currentURL = new URLSearchParams(window.location.search);

  const [selectedResult, setSelectedResult] = useState<string>('Result');
  const [searchValue, setSearchValue] = useState<string | undefined>(searchQuery);
  const [resultsToDisplay, setResultsToDisplay] = useState<AdmissionRequestType[]>([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState<{
    start: string;
    end: string;
  }>({ start: startTime, end: endTime });
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [selectedRequest, setSelectedRequest] = useState<AdmissionRequestType | null>(null);
  const [selectedChart, setSelectedChart] = useState<string>('number');
  const [requestCount, setRequestCount] = useState<RequestCountType>({
    difference: 1,
    requestCount: [],
  });
  const [pastAdmissionRequests, setPastAdmissionRequests] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const isMounted = useRef<boolean>(false);
  const isFirstTimeLoaded = useRef<boolean>(true);

  const timeZone = getCurrentTimezone();

  useEffect(() => {
    logger.logEvent('admission:page-load');
    if (!isMounted.current && isFirstTimeLoaded) {
      init();
      isMounted.current = true;
      isFirstTimeLoaded.current = false;
    }
    return () => {
      isMounted.current = false;
      isFirstTimeLoaded.current = true;
    };
  }, [route?.params?.cluster]);

  const searchedOrFiltered = useMemo(() => {
    return !!(
      searchValue ||
      (selectedResult !== 'Result' && selectedResult !== 'All Results') ||
      (selectedTimeRange.start !== startTime && selectedTimeRange.end !== endTime)
    );
  }, [selectedResult, searchValue, selectedTimeRange.start, selectedTimeRange.end]);

  const checkPastAdmissionRequestUse = async (cluster: string | null): Promise<boolean> => {
    let response = false;

    try {
      response = await sendRequest(
        'GET',
        `${baseURL}/admission-requests/exists?startTime=${selectedTimeRange.start}&endTime=${selectedTimeRange.end}${cluster && cluster !== '' ? '&cluster=' + cluster : ''}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError(strings.clusterOverview.admissionRequestsExistsErrorMsg, e);
    }

    setPastAdmissionRequests(response);
    return response;
  };

  const init = async (pageNumber = pageIndex, size = pageSize) => {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    let cluster = searchParams.get('cluster');
    if (cluster === undefined || cluster === null) {
      cluster = route?.params?.cluster;
    }
    let currentResult;
    if (currentURL.has('success')) {
      currentResult = currentURL.get('success') === 'true';
      setSelectedResult(currentResult ? 'Pass' : 'Fail');
    }
    if (route.params?.operation === strings.selectCluster) {
      setLoading(true);
      setResultsToDisplay([]);
      route.params.operation = '';
      const searchParams: URLSearchParams = new URLSearchParams();
      searchParams.set('startTime', selectedTimeRange.start);
      searchParams.set('endTime', selectedTimeRange.end);
      if (currentResult === undefined && selectedResult === 'Pass') {
        searchParams.set('success', 'true');
      } else if (currentResult === undefined && selectedResult === 'Fail') {
        searchParams.set('success', 'false');
      } else if (currentResult) {
        searchParams.set('success', 'true');
      } else if (currentResult === false) {
        searchParams.set('success', 'false');
      }
      searchParams.delete('page');
      searchParams.delete('pageSize');
      setPageIndex(0);
      setURL({
        route,
        nameToMatch: route.name,
        searchParams: searchParams?.toString(),
        router,
      });
      store().dispatch(UPDATE_CLUSTER, cluster);
    }
    const pastRequests = await checkPastAdmissionRequestUse(cluster);

    if (pastRequests) {
      const { response, difference } = await getRequestsCount(cluster, selectedTimeRange.start, selectedTimeRange.end);
      const { data, headers } = await getAdmissionRequests(
        selectedTimeRange.start,
        selectedTimeRange.end,
        pageNumber,
        size,
        cluster,
        currentResult,
      );

      if (headers['total-size'] === '0' || !data.length) {
        setLoading(false);
        setResultsToDisplay([]);
        setTotalSize(0);
        setRequestCount({ difference: 0, requestCount: [] });
        return;
      }
      setResultsToDisplay(data);
      setRequestCount({ difference, requestCount: response });
      setTotalSize(headers['total-size']);
      await getAllActionItems(data[0]);
    } else {
      setLoading(false);
      setTotalSize(0);
      setResultsToDisplay([]);
      setRequestCount({ difference: 0, requestCount: [] });
    }
  };

  const getRequestsCount = async (
    cluster: string | null,
    start = startTime,
    end = endTime,
  ): Promise<{ response: RequestCountObjectType[]; difference: number }> => {
    const difference = timeZone ? dayjs(end).tz(timeZone).diff(start, 'day') : dayjs(end).diff(start, 'day');
    let response;
    try {
      response = await sendRequest(
        'GET',
        `${baseURL}/admission-requests/count?startTime=${start}&endTime=${end}&interval=${difference > 2 ? 'day' : 'hour'}${cluster && cluster !== '' ? '&cluster=' + cluster : ''}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError(strings.clusterOverview.admissionRequestsCountErrorMsg, e);
    }
    return { response, difference };
  };

  const getAllActionItems = async (response: AdmissionRequestType) => {
    const retrievedActionItems = await getAdmissionRequestByID(response);
    setSelectedRequest(null);
    setSelectedRequest(retrievedActionItems || null);
    setLoading(false);
  };

  const getAdmissionRequests = async (
    startTime: string,
    endTime: string,
    pageNumber: number,
    size: number,
    cluster: string | null,
    success?: boolean,
    search?: string,
    removeSuccess?: boolean,
  ) => {
    const queryURL = `${baseURL}/admission-requests?`;
    const searchParams: URLSearchParams = new URLSearchParams();

    searchParams.set('startTime', startTime);
    searchParams.set('endTime', endTime);
    searchParams.set('page', pageNumber);
    searchParams.set('pageSize', size);
    if (cluster !== null && cluster !== undefined && cluster !== '') {
      searchParams.set('cluster', cluster);
    } else {
      searchParams.delete('cluster');
    }
    if (removeSuccess) {
      searchParams.delete('success');
    } else if (success === undefined && selectedResult === 'Pass') {
      searchParams.set('success', 'true');
    } else if (success === undefined && selectedResult === 'Fail') {
      searchParams.set('success', 'false');
    } else if (success) {
      searchParams.set('success', 'true');
    } else if (success === false) {
      searchParams.set('success', 'false');
    }

    if (search) searchParams.set(`search`, search || '');

    setURL({
      route,
      nameToMatch: route.name,
      searchParams: searchParams.toString(),
      router,
    });
    try {
      return await sendRequest('GET', `${queryURL}${searchParams.toString()}`, { returnHeaders: true }, null);
    } catch (e) {
      logger.logError('error_fetching_admissions', e);
    }
  };

  const getAdmissionRequestByID = async (request: AdmissionRequestType) => {
    try {
      request.Data = await sendRequest(
        'GET',
        `${baseURL}/admission-requests/${request.ID}`,
        { showSuccessAlert: false, cache: false },
        null,
      );
      return request;
    } catch (e) {
      logger.logError('error_retrieving_admission_request_by_id', e);
    }
  };

  const allTimelineItems = React.useMemo(() => {
    const handleTimelineSelection = (request: AdmissionRequestType) => {
      getAllActionItems(request);
      logger.logEvent('admission:select-timeline-event');
    };

    const timelineItems = resultsToDisplay.map((request) => {
      let requestClassName;
      if (request.ID === selectedRequest?.ID) {
        requestClassName = request?.Status + ' active';
      } else {
        requestClassName = request?.Status;
      }
      let status = '';
      if (request?.Status === undefined) {
        // requests from old tables
        status = request.Success ? strings.general.Pass : strings.general.Fail;
      } else {
        // request from new tables
        if (request?.Status === 'pass') {
          status = strings.general.Pass;
        } else if (request?.Status === 'passive_fail') {
          status = strings.general.PassiveFail;
        } else if (request?.Status === 'block') {
          status = strings.general.Blocked;
        } else {
          status = strings.general.Fail;
        }
      }
      return (
        <li className={requestClassName} key={request.ID} onClick={() => handleTimelineSelection(request)}>
          <div className="admission-timeline-item">
            <div className="admission-timeline-content">
              <strong>{status}:</strong>
              {` ${request.Cluster} ${request.ResourceKind} ${request.ResourceName} ${strings.admissionController.inNamespace} ${request.ResourceNamespace}`}
              <ExpandIcon />
            </div>
            <div className="admission-timeline-date">
              {timeZone
                ? dayjs(request.CreatedAt).utc().tz(timeZone).format('MM/DD/YYYY')
                : dayjs(request.CreatedAt).utc().format('MM/DD/YYYY')}{' '}
              {strings.general.at}{' '}
              {timeZone
                ? dayjs(request.CreatedAt).utc().local().tz(timeZone).format('hh:mm A')
                : dayjs(request.CreatedAt).utc().local().format('hh:mm A')}
            </div>
            {request.ActionItemsCount > 0 && (
              <div className="admission-action-items">
                <hr className="admission-action-items-line" />
                <div className="admission-action-items-content">
                  <div className="admission-action-items-icon">
                    <div className="admission-action-items-icon__number">{request.ActionItemsCount}</div>
                  </div>
                  <div className="admission-action-items-link">
                    {strings.admissionController.seeActionItem.replace('itemsCount', request.ActionItemsCount)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </li>
      );
    });

    return (
      <ul className="admission-ul" id="admission-list" tabIndex={0} data-cy="admission-requests-list">
        {timelineItems}
      </ul>
    );
  }, [resultsToDisplay, selectedRequest]);

  const searchForItems = async (event: unknown) => {
    const typedEvent = event as Event | null;
    if (typedEvent) {
      typedEvent.preventDefault();
      setLoading(true);
      setPageIndex(0);
      const inputSearchValue = typedEvent?.target as HTMLInputElement;
      setSearchValue(inputSearchValue.value || undefined);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const getData = async () => {
        try {
          const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
          const cluster = searchParams.get('cluster');
          const { data, headers } = await getAdmissionRequests(
            selectedTimeRange.start,
            selectedTimeRange.end,
            pageIndex,
            pageSize,
            cluster,
            selectedResult === 'Pass' ? true : selectedResult === 'fail' ? false : undefined,
            searchValue,
          );
          store().dispatch(UPDATE_CLUSTER, cluster);
          setTotalSize(headers['total-size']);
          if (data.length) {
            await getAllActionItems(data[0]);
            setResultsToDisplay(data);
          } else {
            setResultsToDisplay([]);
            setLoading(false);
            setRequestCount({ difference: 0, requestCount: [] });
          }
        } catch (e) {
          logger.logError('error_searching_for_admission_requests', e);
        }
      };
      if (route.name === strings.AdmissionRequestsCluster) {
        return;
      }
      getData();
    }, 1000);

    return () => clearTimeout(handler);
  }, [searchValue]);

  const filterResults = async (selection: unknown) => {
    const typedSelection = selection as string;
    setLoading(true);
    setSelectedResult(typedSelection);
    let success;
    if (selection === 'Pass') {
      success = true;
    } else if (selection === 'Fail') {
      success = false;
    }

    try {
      const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
      const cluster = searchParams.get('cluster');
      const { data, headers } = await getAdmissionRequests(
        selectedTimeRange.start,
        selectedTimeRange.end,
        pageIndex,
        pageSize,
        cluster,
        success,
        searchValue,
        selection === 'All Results' ? true : undefined,
      );
      setTotalSize(headers['total-size']);
      if (data.length) {
        getAllActionItems(data[0]);
        setResultsToDisplay(data);
        setLoading(false);
      } else {
        setResultsToDisplay([]);
        setLoading(false);
        setTotalSize(0);
        setRequestCount({ difference: 0, requestCount: [] });
      }
      logger.logEvent('admission:filter-by-result-type', selection);
    } catch (e) {
      logger.logError('error_filtering_admission_requests', e);
    }
  };

  const formatStartAndEndTimes = (start: Date | Dayjs, end: Date | Dayjs) => {
    const newStart = formatDatetime(timeZone ? dayjs(start).tz(timeZone) : dayjs(start));
    const newEnd = formatDatetime(timeZone ? dayjs(end).tz(timeZone) : dayjs(end));
    return [newStart, newEnd];
  };

  const filterByDate = async (start?: Date | Dayjs, end?: Date | Dayjs) => {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    setPageIndex(0);
    const cluster = searchParams.get('cluster');
    if (!start || !end) {
      return;
    }
    setLoading(true);
    setResultsToDisplay([]);
    const currentResult = selectedResult;
    const [newStart, newEnd] = formatStartAndEndTimes(start, end);
    setSelectedTimeRange({ start: newStart, end: newEnd });
    let admissionRequests;
    switch (currentResult) {
      case 'Result':
      case 'All Results':
      default:
        admissionRequests = await getAdmissionRequests(newStart, newEnd, pageIndex, pageSize, cluster);
        break;
    }
    setTotalSize(admissionRequests.headers['total-size']);
    if (admissionRequests.data.length) {
      getAllActionItems(admissionRequests.data[0]);
      const { response, difference } = await getRequestsCount(cluster, newStart, newEnd);
      setRequestCount({ difference, requestCount: response });
      setResultsToDisplay(admissionRequests.data);
    } else {
      setRequestCount({ difference: 0, requestCount: [] });
      setResultsToDisplay([]);
      setLoading(false);
    }
    logger.logEvent('admission:filter-by-date:');
  };

  const changePageSize = (sizeOption: number) => {
    setPageSize(sizeOption);
    init(pageIndex, sizeOption);
    logger.logEvent('admission:change-results-per-page', sizeOption);
  };

  const handlePaginationChange = (page: number): void => {
    setPageIndex(page);
    init(page);
    setResultsToDisplay([]);
    setLoading(true);
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    let cluster = searchParams.get('cluster');
    if (cluster === undefined || cluster === null) {
      cluster = route?.params?.cluster;
    }
    store().dispatch(UPDATE_CLUSTER, cluster);
    logger.logEvent('admission:change-timeline-page', page);
  };

  const handleChartTypeChange = (value: unknown) => {
    const typedValue = value as OptionType;
    if (typedValue) setSelectedChart(typedValue.value);
    logger.logEvent(`admission:change-chart`, typedValue.value);
  };

  const customSelectStyles: StylesConfig<unknown, true> = {
    control: (styles) => ({ ...styles, borderStyle: 'none' }),
    singleValue: (styles) => ({ ...styles, color: COLORS.CORE.PRIMARY }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (styles) => ({ ...styles, color: `${COLORS.CORE.PRIMARY} !important` }),
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'last',
      label: strings.navigation.admissionController,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact className="admission-parent-div">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="admission-top-div">
        <Card className="admission-chart-card">
          <Card.Header className="admission-chart-header">
            <Select
              className="admission-chart-dropdown"
              defaultValue={options[0]}
              options={options}
              onChange={handleChartTypeChange}
              name="chart-dropdown"
              styles={customSelectStyles}
              aria-label="admission chart dropdown"
            />
          </Card.Header>
          <Card.Body className={loading ? 'admission-chart-card-spinner' : ''}>
            <AdmissionChart
              difference={requestCount.difference}
              data={requestCount.requestCount}
              chart={selectedChart}
              pastRequests={pastAdmissionRequests}
              router={router}
              loading={loading}
            />
          </Card.Body>
        </Card>
      </div>
      <div className="admission-bottom-div">
        <SearchFilterBar searchFunction={searchForItems}>
          <Datepicker
            onDateSelect={filterByDate}
            startDate={selectedTimeRange.start}
            endDate={selectedTimeRange.end}
            isClearable={false}
          />
          <DropdownButton
            className="dropdown-btn"
            title={selectedResult}
            onSelect={filterResults}
            data-cy="result-dropdown"
          >
            <DropdownOptions options={['All Results', 'Pass', 'Fail']} />
          </DropdownButton>
        </SearchFilterBar>
        <div className="admission-bottom-div-card-container">
          <Card className="admission-timeline">
            <Card.Body className="admission-timeline-body">
              <Card.Title className="admission-timeline-title">{strings.admissionController.Timeline}</Card.Title>
              {resultsToDisplay && resultsToDisplay.length > 0 && (
                <Card.Subtitle className="admission-timeline-subtitle">
                  {`${strings.general.showingNumber.replace(
                    '#',
                    `${Math.max(pageSize * pageIndex + 1)}-${pageSize * pageIndex + pageSize}`,
                  )} ${totalSize} ${strings.general.results}`}
                  <div className="admission-timeline-subtitle-page-size">
                    <span>{strings.resultsPerPage}</span>
                    <DropdownButton
                      title={pageSize}
                      size="sm"
                      className="admission-timeline-subtitle-page-size__dropdown__page-size"
                    >
                      {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
                        <Dropdown.Item
                          key={idx}
                          onClick={() => {
                            changePageSize(pageSizeOption);
                          }}
                        >
                          {pageSizeOption}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                </Card.Subtitle>
              )}
              {loading && <LoadingSpinner />}
              {!loading && !pastAdmissionRequests && (
                <div>
                  <span>{strings.admissionController.noAdmissionController}</span>
                </div>
              )}
              {pastAdmissionRequests && !loading && !searchedOrFiltered && !resultsToDisplay.length && (
                <div>
                  <span>{strings.admissionController.noData}</span>
                </div>
              )}
              {pastAdmissionRequests && !loading && searchedOrFiltered && !resultsToDisplay.length && (
                <div>
                  <span>{strings.admissionController.noResults}</span>
                </div>
              )}
              {!loading && resultsToDisplay && <>{allTimelineItems}</>}
              {!loading && totalSize > pageSize && (
                <div className="admission-timeline-pagination">
                  <PaginationComponent
                    currentPage={pageIndex}
                    pageChange={handlePaginationChange}
                    totalCount={Math.ceil(totalSize / pageSize)}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
          {resultsToDisplay.length > 0 && (
            <DescriptionPanel
              request={selectedRequest}
              router={router}
              route={route}
              store={store}
              caption="Action Items of Admission Requests"
              reloadAdmissionRequest={getAdmissionRequestByID}
            />
          )}
        </div>
        <Toaster />
      </div>
    </LayoutReact>
  );
};

export default ReactAdmission;
