import { Cluster } from '~globalTypes';
import {
  VulnerabilitiesActionItems as AllImagesVulnerabilitiesActionItems,
  VulnerabilitiesActionItemsResponse as AllImagesVulnerabilitiesActionItemsResponse,
} from './AllImages/components/VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import { VulnerabilitiesActionItems as AllVulnerabilitiesActionItems } from './AllVulnerabilities/components/VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import { ImageDetailItem } from './ImageDetail/components/ImageDetailItemsTable/ImageDetailItemsTable.types.react';
import { AppGroup } from '~views/organization/actionItems/ActionItems.types.react';

export type SummariesType = AllImagesVulnerabilitiesActionItemsResponse[] | AllVulnerabilitiesActionItems[];

export type VulnerabilitiesResponseItemsType = SummariesType | ImageDetailItem[];

export type VulnerabilitiesItemsType =
  | AllImagesVulnerabilitiesActionItems[]
  | AllVulnerabilitiesActionItems[]
  | ImageDetailItem[];

export interface ExportCSVParams {
  action: string;
  exportAllCallback: () => any;
  exportFilteredCallback: () => any;
  totalExportFilteredItems?: number;
  searchParams?: URLSearchParams;
  logEventKey?: string;
  cluster?: string;
}

export interface ExportParams {
  apiEndpoint?: string;
  from?: string;
  orgName?: string;
}

export interface ActionItemsResponse {
  data?: {
    summaries?: SummariesType;
    vulnerabilities?: ImageDetailItem[];
    total?: number;
  };
  headers?: { [key: string]: unknown };
}

export interface GetChartURLSearchParams {
  groupBy: string;
  cluster: Cluster | undefined;
  isResolvedShown: boolean | undefined;
  selectedAppGroup: AppGroup | null;
  firstSeen?: string | null;
  firstSeenEnd?: string | null;
}

export interface SingleSelectedAction {
  content?: string;
  title?: string;
  confirmButtonText?: string;
  action?: () => void;
}

export interface ConfirmationButton {
  text?: string;
  action?: () => void;
}

export interface MultiSelectedAction {
  content?: string;
  title?: string;
  confirmationButtons?: ConfirmationButton[];
}

export function isSingleSelectionAction(
  object?: MultiSelectedAction | SingleSelectedAction | null,
): object is SingleSelectedAction {
  if (!object) return false;
  return 'confirmButtonText' in object;
}
