import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

export const DEFAULT_FILTERS = {
  Severity: [
    strings.general.critical,
    strings.general.high,
    strings.general.medium,
    strings.general.low,
    strings.general.none.toLowerCase(),
  ],
  Category: [strings.general.efficiency, strings.general.reliability, strings.general.security],
};

export const ADVANCED_FILTERS_MAPPER: Record<string, string> = {
  Cluster: 'Cluster',
  ReportType: 'Reports',
  ResourceNamespace: 'Namespace',
  Team: 'Team',
  Severity: 'Severity',
  Category: 'Category',
};

export const SEARCH_PARAMS_MAPPER: Record<string, string> = {
  Cluster: strings.aggregators.cluster,
  Reports: strings.aggregators.reportType,
  Namespace: strings.aggregators.namespace,
  Team: 'teams',
  Severity: strings.aggregators.severity,
  Category: strings.aggregators.category,
  StartTime: 'startTime',
  EndTime: 'endTime',
  ReportStatus: 'reportStatus',
};

export const REVERSED_SEARCH_PARAMS_MAPPER: Record<string, string> = {
  cluster: 'Cluster',
  reportType: 'Reports',
  namespace: 'Namespace',
  teams: 'Team',
  severity: 'Severity',
  category: 'Category',
};

export const TEAM = 'Team';

export const TEAMS = 'teams';

interface GenericOptionType<T> {
  value: T;
  label: string;
}

export type ReportStatusValue = 'open' | 'introduced' | 'resolved' | 'fixed' | 'deleted';
export type ReportStatusOptionType = GenericOptionType<ReportStatusValue>;

export const CHART_COLOR_MAPPER: Record<ReportStatusValue, string> = {
  open: COLORS.CHARTS.ACTION_ITEMS_REPORTS.OPEN,
  introduced: COLORS.CHARTS.ACTION_ITEMS_REPORTS.INTRODUCED,
  resolved: COLORS.CHARTS.ACTION_ITEMS_REPORTS.RESOLVED,
  fixed: COLORS.CHARTS.ACTION_ITEMS_REPORTS.FIXED,
  deleted: COLORS.CHARTS.ACTION_ITEMS_REPORTS.DELETED,
};

export const TOOLTIP_CONTENT_MAPPER = (
  period = strings.actionItemsReports.month,
): Record<ReportStatusValue, string> => ({
  open: strings.actionItemsReports.openTooltip.replaceAll('$PERIOD', period),
  introduced: strings.actionItemsReports.introducedTooltip.replaceAll('$PERIOD', period),
  resolved: strings.actionItemsReports.manuallyResolvedTooltip.replaceAll('$PERIOD', period),
  fixed: strings.actionItemsReports.fixedTooltip.replaceAll('$PERIOD', period),
  deleted: strings.actionItemsReports.deletedTooltip.replaceAll('$PERIOD', period),
});

export const CUMULATIVE_TOOLTIP_CONTENT_MAPPER = (
  period = strings.actionItemsReports.month,
): Record<ReportStatusValue, string> => ({
  open: strings.actionItemsReports.cumulativeOpenTooltip.replaceAll('$PERIOD', period),
  introduced: strings.actionItemsReports.cumulativeIntroducedTooltip,
  resolved: strings.actionItemsReports.cumulativeManuallyResolvedTooltip,
  fixed: strings.actionItemsReports.cumulativeFixedTooltip,
  deleted: strings.actionItemsReports.cumulativeDeletedTooltip,
});

export const ACTION_ITEMS_REPORTS_CONTEXT_MENU_ID = 'ACTION_ITEMS_REPORTS_CONTEXT_MENU_ID';

export const SELECTED_REPORT_STATUS = 'selected_report_status';
