import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ChartProps } from './AdmissionChart.types.react';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

function NumberChart({ formattedData, yMax, yAxisLabel, chart }: ChartProps) {
  return (
    <section className="admission-chart" aria-label={strings.admissionController.chartAriaLabel}>
      <ResponsiveLine
        data={formattedData}
        margin={{ top: 20, right: 50, bottom: 60, left: 60 }}
        colors={[COLORS.CORE.DANGER, COLORS.CORE.SUCCESS.DEFAULT]}
        theme={{
          axis: {
            legend: {
              text: {
                fontWeight: 500,
              },
            },
          },
        }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 0, max: yMax, stacked: true, reverse: false }}
        yFormat=" >-0.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 25,
          legendOffset: 50,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisLabel,
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        lineWidth={0}
        enablePoints={chart === 'number'}
        pointSize={7}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'color' }}
        pointLabelYOffset={-12}
        enableArea={true}
        enableGridX={false}
        enableGridY={false}
        areaOpacity={1}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          return (
            <div className="admission-chart__tooltip">
              {slice.points.map((point) => (
                <div key={point.id} className="admission-chart__tooltip-info">
                  <strong>{point.serieId}: </strong>{' '}
                  {typeof point.data.yFormatted === 'string'
                    ? parseInt(point.data.yFormatted)
                    : point.data.yFormatted}
                  {chart === strings.percentage ? '%' : ''}
                </div>
              ))}
            </div>
          );
        }}
      />
    </section>
  );
}

export default NumberChart;
