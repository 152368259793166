import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Card } from '@fairwindsops/ui-components';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import LargeBarChart from '~reactComponents/charts/LargeBarChart/LargeBarChart.react';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';

import { strings } from '~utils/strings';
import { ActionItemReportChartData } from '~utils/global.types.react';

import './ActionItemsReportsBarChart.react.scss';

type ActionItemsReportsBarChartProps = {
  chartTitle?: string;
  data: ActionItemReportChartData[];
  isLoading?: boolean;
  chartColors?: string[];
  tooltip?: string;
};

const ActionItemsReportsBarChart = ({
  chartColors,
  chartTitle,
  data,
  isLoading,
  tooltip,
}: ActionItemsReportsBarChartProps) => {
  return (
    <div>
      <Card className="action-items-reports-bar-chart">
        <Card.Title className="action-items-reports-bar-chart__title">
          {chartTitle ? (
            <div>
              {chartTitle}{' '}
              <span className="action-items-reports-bar-chart__aggregators">
                {strings.actionItemsReports.actionItems}
              </span>
            </div>
          ) : (
            `${strings.actionItemsReports.actionItems}`
          )}
          {chartTitle ? (
            <div>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id={`tooltip-action-items-reports-bar-chart`}>
                    <Popover.Content>{tooltip}</Popover.Content>
                  </Popover>
                }
              >
                <InfoIcon />
              </OverlayTrigger>
            </div>
          ) : null}
        </Card.Title>
        <Card.Body>
          {isLoading && (
            <LoadingSpinner containerClassNames="action-items-reports-bar-chart__spinner" />
          )}
          {!isLoading && data?.length > 0 ? (
            <div className="action-items-reports-bar-chart__chart">
              <LargeBarChart
                data={data}
                keys={['value']}
                indexBy="label"
                margin={{ top: 20, right: 0, bottom: 30, left: 45 }}
                // avoid warning from OrdinalColorScaleConfig type
                // eslint-disable-next-line
                // @ts-ignore
                colors={chartColors}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }}
                tooltip={(data: any) => (
                  <div className="action-items-reports-bar-chart__tooltip">
                    <strong>{`${chartTitle}${strings.punctuation.colon}`}</strong>
                    <span>{data.value}</span>
                  </div>
                )}
              />
            </div>
          ) : null}
          {!isLoading && !data?.length ? (
            <div className="action-items-reports-bar-chart__no-aggregator">
              {strings.general.noDataToDisplay}
            </div>
          ) : null}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ActionItemsReportsBarChart;
