import { StylesConfig } from 'react-select';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

export const colors = {
  maxUsage: '#932D4C',
  minUsage: '#6F569D',
  averageUsage: '#D7743F',
  limits: '#448867',
  requests: '#448867',
  'settings-limits': '#445688',
  'settings-requests': '#445688',
};

export const labels = {
  maxUsage: strings.efficiency.maxUsage,
  minUsage: strings.efficiency.minUsage,
  averageUsage: strings.efficiency.avgUsage,
  limits: strings.efficiency.recLimits,
  requests: strings.efficiency.recRequests,
  'settings-limits': strings.efficiency.actualLimits,
  'settings-requests': strings.efficiency.actualRequests,
};

export const legendLabels = {
  minUsage: strings.efficiency.minUsage,
  averageUsage: strings.efficiency.avgUsage,
  maxUsage: strings.efficiency.maxUsage,
};

export const usageChartKeys = [
  'maxUsage',
  'minUsage',
  'limits',
  'requests',
  'averageUsage',
  'settings-limits',
  'settings-requests',
];

export const usageLineColors = {
  maxUsage: COLORS.CHARTS.USAGE_LINE.MAX,
  minUsage: COLORS.CHARTS.USAGE_LINE.MIN,
  averageUsage: COLORS.CHARTS.USAGE_LINE.AVG,
  limits: COLORS.CHARTS.USAGE_LINE.LIMITS,
  requests: COLORS.CHARTS.USAGE_LINE.LIMITS,
  'settings-limits': COLORS.CHARTS.USAGE_LINE.SETTINGS_LIMITS,
  'settings-requests': COLORS.CHARTS.USAGE_LINE.SETTINGS_LIMITS,
};

export const styleById = {
  limits: {
    strokeDasharray: '12, 6',
    strokeWidth: 2,
  },
  requests: {
    strokeDasharray: '12, 6',
    strokeWidth: 2,
  },
  'settings-limits': {
    strokeDasharray: '12, 6',
    strokeWidth: 2,
  },
  'settings-requests': {
    strokeDasharray: '12, 6',
    strokeWidth: 2,
  },
  default: {
    strokeWidth: 2,
  },
};

export const dropdownOptions = [
  { value: strings.noTranslate.cpuRaw, label: 'CPU' },
  { value: strings.noTranslate.memoryRaw, label: 'Memory' },
];

export type workloadRecSettings = { value: 'current' | 'historical'; label: string };

export const currentOptions: workloadRecSettings = { value: 'current', label: 'Current Request/Limits' };
export const historicalOptions: workloadRecSettings = { value: 'historical', label: 'Historical Request/Limits' };

export const settingsOptions = [currentOptions, historicalOptions];

export const customSelectStyles: StylesConfig<unknown, true> = {
  control: (styles) => ({ ...styles, borderStyle: 'none' }),
  singleValue: (styles) => ({ ...styles, color: COLORS.CORE.PRIMARY }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: (styles) => ({ ...styles, color: `${COLORS.CORE.PRIMARY} !important` }),
  valueContainer: (styles) => ({ ...styles, textTransform: strings.noTranslate.capitalize }),
};
