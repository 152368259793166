import React from 'react';
import clsx from 'clsx';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import ActionItemsTable from '../components/ActionItemsTable/ActionItemsTable.react';
import RepoActivities from '../components/RepoActivities/RepoActivities.react';
import RepositoriesChart from '../components/RepoChart/RepoChart.react';

import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import { Labels } from './ReactRepositories.types.react';
import { IRoute, IRouter } from '~globalTypes';

import useVuexStore from '~hooks/useVuexStore';

import { handlePageChange } from '~utils/global.helpers.react';
import { Title } from '~utils/texts.react';

import './ReactRepositories.react.scss';
import { strings } from '~utils/strings';

type ReactRepositoriesProps = {
  route: IRoute;
  router: () => IRouter;
};

const ReactRepositories = ({ route, router }: ReactRepositoriesProps) => {
  const { organization, repositories, cluster } = useVuexStore();
  const baseURL = `/v0/organizations/${organization.Name}`;

  const org = organization.Name;
  const allReposRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'repositories',
      label: Labels.Repositories,
      href: `/orgs/${org}/repositories`,
    },
    {
      id: 'last',
      label: Labels.Overview,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <div className="repositories-breadcrumbs">
        <Breadcrumbs
          data={allReposRoutesList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
      </div>
      {repositories.length ? (
        <>
          <div className="repositories__container">
            <div className="repositories__container-left">
              <RepositoriesChart
                organization={organization}
                repositories={repositories}
                router={router}
              />
            </div>
            <div className="repositories__container-right">
              <RepoActivities
                organization={organization}
                router={router}
                repositories={repositories}
              />
            </div>
          </div>
          <ActionItemsTable
            baseURL={baseURL}
            isMainBranch
            organization={organization}
            repositories={repositories}
            route={route}
            router={router}
            savedSelectionsKey={Labels.SaveSelectionsKey}
            cluster={cluster?.Name || ''}
          />
        </>
      ) : (
        <div
          className={clsx(
            'no-repos-text',
            Title({ size: strings.textStyling.xs, color: strings.textStyling.primary }),
          )}
        >
          <span>Please add a repository above</span>
        </div>
      )}
    </LayoutReact>
  );
};

export default ReactRepositories;
