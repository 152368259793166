import React from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import NumberChart from './Chart.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { AdmissionChartProps, Data } from './AdmissionChart.types.react';
import { RequestCountObjectType } from '../../ReactAdmission.types.react';

import { strings } from '~utils/strings';
import { getCurrentTimezone } from '~utils/global.helpers.react';
import { COLORS } from '~utils/styling';

import './AdmissionChart.react.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const failData: Data = {
  id: 'Fail',
  color: COLORS.CORE.DANGER,
  data: [],
};

const passData: Data = {
  id: 'Pass',
  color: COLORS.CORE.SUCCESS.DEFAULT,
  data: [],
};

const AdmissionChart = ({
  difference,
  data,
  chart,
  pastRequests,
  router,
  loading,
}: AdmissionChartProps) => {
  const timeZone = getCurrentTimezone();
  const formatDate = (date: Date): string => {
    return difference > 2
      ? timeZone
        ? dayjs(date).utc().local().tz(timeZone).format('MM/DD/YY')
        : dayjs(date).utc().local().format('MM/DD/YY')
      : timeZone
        ? dayjs(date).utc().local().tz(timeZone).format('hh:mm A')
        : dayjs(date).utc().local().format('hh:mm A');
  };

  const formattedData = React.useMemo(() => {
    failData.data = [];
    passData.data = [];
    if (chart === 'number') {
      data.forEach((datum: RequestCountObjectType) => {
        const formattedDate = formatDate(datum.CreatedDay);
        failData.data.push({ x: formattedDate, y: datum.FailuresCount });
        passData.data.push({ x: formattedDate, y: datum.SuccessCount });
      });
      return [failData, passData];
    } else {
      data.forEach((datum: RequestCountObjectType) => {
        const formattedDate = formatDate(datum.CreatedDay);
        const failPercentage =
          (datum.FailuresCount / (datum.FailuresCount + datum.SuccessCount)) * 100;
        const successPercentage =
          (datum.SuccessCount / (datum.FailuresCount + datum.SuccessCount)) * 100;
        failData.data.push({ x: formattedDate, y: failPercentage });
        passData.data.push({ x: formattedDate, y: successPercentage });
      });
      return [failData, passData];
    }
  }, [JSON.stringify(data), chart]);

  const yAxisLabel =
    chart === 'number'
      ? strings.admissionController.numberChart
      : strings.admissionController.percentChart;

  const yMax = chart === 'number' ? 'auto' : 100;

  const goToInstallPage = () => {
    router().push({ path: 'report-hub/report/admission' });
  };

  return (
    <div className={!pastRequests && !data.length && !loading ? 'admission-chart__no-data' : ''}>
      {loading && !data.length && <LoadingSpinner />}
      {!pastRequests && !data.length && !loading && (
        <div className="admission-chart__no-data__information">
          <h2 className="admission-chart__no-data__information-title">{strings.general.noData}</h2>
          <h3 className="admission-chart__no-data__information-subtitle">
            {strings.admissionController.setUpAdmission}
          </h3>
          <Button
            className="admission-chart__no-data__information-button"
            onClick={goToInstallPage}
          >
            {strings.admissionController.setUpAgent}
          </Button>
        </div>
      )}
      {pastRequests && data.length < 2 && !loading && (
        <div className="admission-chart__not-enough">
          <h2 className="admission-chart__not-enough-text">
            {strings.admissionController.changeTimeRange}
          </h2>
        </div>
      )}
      {data.length > 1 && !loading && (
        <NumberChart
          formattedData={formattedData}
          yMax={yMax}
          yAxisLabel={yAxisLabel}
          chart={chart}
        />
      )}
    </div>
  );
};

export default AdmissionChart;
